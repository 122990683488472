import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AADUser,
  AdditionUnit,
  ApplicationNotification,
  ChannelType,
  Country,
  Day,
  Frequency,
  Group,
  Ishtar365CommunicationService,
  LoaderService,
  Lookup,
  MicrosoftAuthenticationService,
  Month,
  Ranking,
  TranslationService,
  TriggerType,
  getTodayInUTC,
} from 'processdelight-angular-components';

import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  filter,
  first,
  forkJoin,
  map,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Absence } from '../models/project/absence';
import { AbsenceType } from '../models/project/absenceType';
import { ContactDetail } from '../models/project/contactDetail';
import { ContactDetailParam } from '../models/project/contactDetailParam';
import { ContactDetailParamValue } from '../models/project/contactDetailParamValue';
import { Department } from '../models/project/department';
import { Exchange } from '../models/project/exchange';
import { PermanenceDuty } from '../models/project/permanenceDuty';
import { PermanenceTemplate } from '../models/project/permanenceTemplate';
import { Recurrency } from '../models/project/recurrency';
import { AppConfig } from '../models/user/appConfig';
import { GroupUser } from 'processdelight-angular-components';
import { UserSettings } from '../models/user/userSettings';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { getAbsenceTypesResolved } from '../store/absence-type/absence-type.actions';
import {
  getAbsencePeriodsResolved,
  getAbsencesForUserResolved,
  getAbsencesResolved,
} from '../store/absence/absence.actions';
import { getContactDetailsResolved } from '../store/contactDetail/contactDetail.actions';
import { getContactDetailParamsResolved } from '../store/contactDetailParam/contactDetailParam.actions';
import { getContactDetailParamValuesResolved } from '../store/contactDetailParamValue/contactDetailParamValue.actions';
import {
  getDepartmentsResolved,
  getDepartments,
} from '../store/department/department.actions';
import {
  getExchangesResolved,
  getPermanenceDutiesResolved,
} from '../store/permanenceDuty/permanenceDuty.actions';
import { getPermanenceTemplateResolved } from '../store/permanenceTemplate/permanenceTemplate.actions';
import { getRecurrenciesResolved } from '../store/recurrence/recurrence.actions';
import { IshtarPermanenceService } from './project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { DateTime } from 'luxon';
import { getNotificationsResolved } from '../store/applicationNotification/applicationNotification.action';

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);

export const varcountries$ = new BehaviorSubject<Country[] | undefined>(
  undefined
);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);
export const groupUsers$ = combineLatest([vargroups$, varusers$]).pipe(
  map(([groups, users]) => (groups ?? []).concat(users ?? []))
);

export const varfrequencies$ = new BehaviorSubject<Frequency[] | undefined>(
  undefined
);

export const varnotificationtriggertypes$ = new BehaviorSubject<
  TriggerType[] | undefined
>(undefined);

export const varnotificationadditionunits$ = new BehaviorSubject<
  AdditionUnit[] | undefined
>(undefined);

export const varchanneltypes$ = new BehaviorSubject<ChannelType[] | undefined>(
  undefined
);

export const vardays$ = new BehaviorSubject<Day[] | undefined>(undefined);
export const varmonths$ = new BehaviorSubject<Month[] | undefined>(undefined);
export const varrankings$ = new BehaviorSubject<Ranking[] | undefined>(
  undefined
);

export const vartranslations$ = new BehaviorSubject<any>({});
export const varusersettings = new BehaviorSubject<UserSettings | undefined>(
  undefined
);
export const varappconfig = new BehaviorSubject<AppConfig | undefined>(
  undefined
);

export const appName = 'Ishtar.Permanence';

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  isSaving = new Subject<string>();
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private loaderService: LoaderService,
    private translationService: TranslationService,
    private router: Router,
    private _store: Store<AppState>,
    private ishtar365communicationService: Ishtar365CommunicationService,
    private _snackBar: MatSnackBar,
    private msal: MicrosoftAuthenticationService
  ) {}

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLicense(tenantId: string) {
    return this.loaderService.startLoading$(
      'Retrieving license information',
      () =>
        this.ishtarPermanenceService.getLicense(tenantId).pipe(
          tap((data) => varlicense$.next(data)),
          catchError((error) => {
            this.router.navigate(['/401']);
            throw error;
          })
        )
    );
  }

  getTypes() {
    return this.ishtarPermanenceService.getTypes().pipe(
      tap((types) => {
        varcountries$.next(types.countries ? types.countries : []);

        vardays$.next(
          types.days
            ? types.days.map((a: Day) => new Day(this.camelcaseKeys(a)))
            : []
        );
        varmonths$.next(
          types.months
            ? types.months.map((a: Month) => new Month(this.camelcaseKeys(a)))
            : []
        );
        varrankings$.next(
          types.rankings
            ? types.rankings.map(
                (a: Ranking) => new Ranking(this.camelcaseKeys(a))
              )
            : []
        );

        varfrequencies$.next(
          types.frequencies
            ? types.frequencies.map(
                (a: Frequency) => new Frequency(this.camelcaseKeys(a))
              )
            : []
        );
        varnotificationtriggertypes$.next(
          types.triggerTypes
            ? types.triggerTypes.map(
                (a: TriggerType) => new TriggerType(this.camelcaseKeys(a))
              )
            : []
        );
        varnotificationadditionunits$.next(
          types.additionUnits
            ? types.additionUnits.map(
                (a: AdditionUnit) => new AdditionUnit(this.camelcaseKeys(a))
              )
            : []
        );
        varchanneltypes$.next(
          types.channelTypes
            ? types.channelTypes.map(
                (a: ChannelType) => new ChannelType(this.camelcaseKeys(a))
              )
            : []
        );
      })
    );
  }

  getAbsences(startDate: DateTime, endDate: DateTime) {
    this.ishtarPermanenceService
      .getAbsencesData(startDate, endDate)
      .pipe(first())
      .subscribe((data) => {
        this._store.dispatch(
          getAbsencesResolved({
            result: data.absences
              ? data.absences.map(
                  (a: Absence) => new Absence(this.camelcaseKeys(a))
                )
              : [],
          })
        );
        this._store.dispatch(
          getAbsencesForUserResolved({
            result: data.absencesForUser
              ? data.absencesForUser.map(
                  (a: Absence) => new Absence(this.camelcaseKeys(a))
                )
              : [],
          })
        );
        this._store.dispatch(
          getAbsenceTypesResolved({
            result: data.absenceTypes
              ? data.absenceTypes.map(
                  (a: AbsenceType) => new AbsenceType(this.camelcaseKeys(a))
                )
              : [],
          })
        );
      });
  }

  getContactDetails() {
    return this.ishtarPermanenceService.getContactDetailsData().pipe(
      first(),
      tap((data) => {
        this._store.dispatch(
          getContactDetailsResolved({
            result: data.contactDetails
              ? data.contactDetails.map(
                  (a: ContactDetail) => new ContactDetail(this.camelcaseKeys(a))
                )
              : [],
          })
        );
        this._store.dispatch(
          getContactDetailParamsResolved({
            result: data.contactDetailParams
              ? data.contactDetailParams.map(
                  (a: ContactDetailParam) =>
                    new ContactDetailParam(this.camelcaseKeys(a))
                )
              : [],
          })
        );
        this._store.dispatch(
          getContactDetailParamValuesResolved({
            result: data.contactDetailParamValues
              ? data.contactDetailParamValues.map(
                  (a: ContactDetailParamValue) =>
                    new ContactDetailParamValue(this.camelcaseKeys(a))
                )
              : [],
          })
        );
      })
    );
  }

  getExchanges() {
    return this.ishtarPermanenceService
      .getExchanges()
      .pipe(first())
      .subscribe((data) => {
        this._store.dispatch(
          getExchangesResolved({
            exchanges: data
              ? data.map((a: Exchange) => new Exchange(this.camelcaseKeys(a)))
              : [],
          })
        );
      });
  }

  getRecurrencies() {
    this.ishtarPermanenceService
      .getRecurrencies()
      .pipe(first())
      .subscribe((data) => {
        this._store.dispatch(
          getRecurrenciesResolved({
            result: data ? data : [],
          })
        );
      });
  }

  getAbsencePeriods() {
    this.ishtarPermanenceService
      .getAbsencePeriods()
      .pipe(first())
      .subscribe((data) => {
        this._store.dispatch(
          getAbsencePeriodsResolved({
            result: data ? data : [],
          })
        );
      });
  }

  getPermanenceDuties(startDate: DateTime, endDate: DateTime) {
    return this.ishtarPermanenceService
      .getPermanenceDuties(startDate, endDate)
      .pipe(
        first(),
        tap((data) => {
          this._store.dispatch(
            getPermanenceDutiesResolved({
              result: data
                ? data.map(
                    (a: PermanenceDuty) =>
                      new PermanenceDuty(this.camelcaseKeys(a))
                  )
                : [],
            })
          );
        })
      );
  }

  getDepartments() {
    return this.ishtarPermanenceService.getDepartments().pipe(
      first(),
      tap((data) => {
        this._store.dispatch(
          getDepartmentsResolved({
            result: data
              ? data
                  .filter((d) => !d.isDeleted)
                  .map((a: Department) => new Department(this.camelcaseKeys(a)))
              : [],
          })
        );
      })
    );
  }

  boot() {
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        tap(() => console.log('Keep alive')),
        switchMap(() => this.ishtarPermanenceService.sessionKeepAlive())
      )
      .subscribe();

    return this.getLicense(this.msal.tenantId).pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (
          !license?.licenses.some((l) => l.productName == 'Ishtar.Permanence')
        ) {
          this.router.navigate(['/401']);
          throw new Error('No license for Ishtar.Permanence');
        }
        // return forkJoin([
        const startOfMonth = getTodayInUTC().startOf('month'); //new Date(Date.UTC(currentYear, currentMonth, 1));
        const endOfMonth = getTodayInUTC().endOf('month'); //new Date(Date.UTC(currentYear, currentMonth + 1, 0));
        let weekStartStartOfMonth = startOfMonth.startOf('week');
        // new Date(
        //   new Date(startOfMonth).setUTCDate(
        //     startOfMonth.getUTCDate() -
        //       startOfMonth.getUTCDay() +
        //       (startOfMonth.getUTCDay() == 0 ? -6 : 1)
        //   )
        // );
        if (startOfMonth.weekday == 1) {
          weekStartStartOfMonth = weekStartStartOfMonth.minus({ days: 7 });
          // new Date(
          //   weekStartStartOfMonth.valueOf() - 7 * oneDay
          // );
        }
        const weekStartEndOfMonth = endOfMonth.startOf('week');
        // new Date(
        //   new Date(endOfMonth).setUTCDate(
        //     endOfMonth.getUTCDate() -
        //       endOfMonth.getUTCDay() +
        //       (endOfMonth.getUTCDay() == 0 ? -6 : 1)
        //   )
        // );
        const weekEndEndOfMonth = endOfMonth.endOf('week');
        // new Date(
        //   new Date(
        //     new Date(endOfMonth).setUTCDate(
        //       endOfMonth.getUTCDate() +
        //         (endOfMonth.getUTCDay() == 0 ? 0 : 7 - endOfMonth.getUTCDay())
        //     )
        //   ).setUTCHours(23, 59)
        // );

        //this.getRecurrencies();
        //this.getExchanges();

        return this.loaderService.startLoading$('Starting application...', () =>
          forkJoin([
            this.ishtarPermanenceService.getStartUpData(
              license.email,
              license.language!
            ),

            //this.getPermanenceDuties(weekStartStartOfMonth, weekEndEndOfMonth),
            this.getContactDetails(),
            this.getTypes(),
            this.getDepartments(),
          ]).pipe(
            tap(([data]) => {
              if (data.appConfig.absencesEnabled) {
                this.getAbsencePeriods();
                this.getAbsences(weekStartStartOfMonth, weekEndEndOfMonth);
              }
              try {
                this.ishtar365communicationService.init();
                this.ishtar365communicationService.registerRedirectActions({
                  ExchangeCreated: (id) => {
                    this.router.navigate(['/my-duties'], {
                      queryParams: { action: 'Created' },
                    });
                  },
                  ExchangeAccepted: (id) => {
                    this.router.navigate(['/my-duties'], {
                      queryParams: { action: 'Accepted' },
                    });
                  },
                });
              } catch {
                // not running in Ishtar365
              }
              varlicense$.next(license);
              vartranslations$.next(data.translations ? data.translations : {});
              this.translationService.update(
                data.translations ? data.translations : {}
              );
              varusers$.next(
                data.users
                  ? data.users.map(
                      (u: GroupUser) =>
                        new GroupUser({
                          user: new AADUser(this.camelcaseKeys(u.user!)),
                        })
                    )
                  : []
              );
              vargroups$.next(
                data.groups
                  ? data.groups.map(
                      (u: GroupUser) =>
                        new GroupUser({
                          group: new Group(this.camelcaseKeys(u.group!)),
                        })
                    )
                  : []
              );

              varusersettings.next(
                data.userSettings ? data.userSettings : new UserSettings({})
              );
              varappconfig.next(
                data.appConfig ? data.appConfig : new AppConfig({})
              );

              this._store.dispatch(
                getNotificationsResolved({
                  notifications: data.notifications
                    ? data.notifications.map(
                        (a: ApplicationNotification) =>
                          new ApplicationNotification(this.camelcaseKeys(a))
                      )
                    : [],
                })
              );
              this._store.dispatch(
                getPermanenceTemplateResolved({
                  result: data.templates
                    ? data.templates.map(
                        (a: PermanenceTemplate) =>
                          new PermanenceTemplate(this.camelcaseKeys(a))
                      )
                    : [],
                })
              );
              this._store.dispatch(
                getExchangesResolved({
                  exchanges: data.exchanges
                    ? data.exchanges.map(
                        (a: Exchange) => new Exchange(this.camelcaseKeys(a))
                      )
                    : [],
                })
              );

              this._store.dispatch(
                getRecurrenciesResolved({
                  result: data.recurrencies
                    ? data.recurrencies.map(
                        (a: Recurrency) => new Recurrency(this.camelcaseKeys(a))
                      )
                    : [],
                })
              );
              this._store.dispatch(
                getPermanenceDutiesResolved({
                  result: data.permanenceDuties
                    ? data.permanenceDuties.map(
                        (a: PermanenceDuty) =>
                          new PermanenceDuty(this.camelcaseKeys(a))
                      )
                    : [],
                })
              );
            }),
            catchError((error) => {
              this._snackBar
                .open(
                  'Error while starting application. Please refresh the page.',
                  'X',
                  {
                    panelClass: 'app-notification-error',
                  }
                )
                ._dismissAfter(10000);
              return of(true);
            })
          )
        );
      })
    );
  }
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
}
