import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  createNotification,
  createNotificationResolved,
  deleteNotification,
  deleteNotificationResolved,
  getNotifications,
  getNotificationsResolved,
  updateNotification,
  updateNotificationResolved,
} from './applicationNotification.action';
import { IshtarPermanenceService } from '../../services/project.service';
import { vartranslations$ } from '../../services/startup.service';

@Injectable({ providedIn: 'root' })
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private api: IshtarPermanenceService,
    private snackbar: MatSnackBar
  ) {}

  getTranslation(label: string): string {
    return vartranslations$.value[label];
  }

  getNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotifications),
      switchMap(({ callback, error }) =>
        this.api.getNotifications().pipe(
          tap((notifications) =>
            callback ? callback(notifications) : undefined
          ),
          switchMap((notifications) =>
            of(getNotificationsResolved({ notifications }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  createNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(createNotification),
      mergeMap(({ notificationRequest, callback, error }) =>
        this.api.createNotification(notificationRequest).pipe(
          tap((notification) =>
            callback ? callback(notification) : undefined
          ),
          mergeMap((notification) => {
            this.snackbar.open(
              this.getTranslation('notificationCreated'),
              'X',
              {
                panelClass: 'app-notification-success',
                duration: 3000,
              }
            );
            const sendInstantMessage = notificationRequest.sendInstantMessage ?? false;
            return of(createNotificationResolved({ notification, instantMessage: sendInstantMessage }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateNotification'),
              'X',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );

            return [];
          })
        )
      )
    )
  );

  updateNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotification),
      mergeMap(({ notificationRequest, callback, error }) =>
        this.api.updateNotification(notificationRequest).pipe(
          tap((notification) =>
            callback ? callback(notification) : undefined
          ),
          mergeMap((notification) => {
            this.snackbar.open(
              this.getTranslation('notificationUpdated'),
              'X',
              {
                panelClass: 'app-notification-success',
                duration: 3000,
              }
            );
            const sendInstantMessage = notificationRequest.sendInstantMessage ?? false;
            return of(updateNotificationResolved({ notification, instantMessage: sendInstantMessage }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorUpdatingNotification'),
              'X',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );

  deleteNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNotification),
      mergeMap(({ notificationId, callback, error }) =>
        this.api.deleteNotification(notificationId).pipe(
          tap(() => (callback ? callback(notificationId) : undefined)),
          mergeMap(() => {
            this.snackbar.open(
              this.getTranslation('notificationDeleted'),
              'X',
              {
                panelClass: 'app-notification-success',
                duration: 3000,
              }
            );
            return of(deleteNotificationResolved({ notificationId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorDeleteNotification'),
              'X',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
}
