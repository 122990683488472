import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  ApplicationNotification,
  CreateNotificationRequest,
  Filter,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { map, tap } from 'rxjs';
import { Absence } from '../models/project/absence';
import { AbsenceType } from '../models/project/absenceType';
import { ContactDetail } from '../models/project/contactDetail';
import { ContactDetailParam } from '../models/project/contactDetailParam';
import { ContactDetailParamValue } from '../models/project/contactDetailParamValue';
import { Department } from '../models/project/department';
import { Exchange } from '../models/project/exchange';
import { PermanenceDuty } from '../models/project/permanenceDuty';
import { PermanenceTemplate } from '../models/project/permanenceTemplate';
import { Recurrency } from '../models/project/recurrency';
import { UserSettings } from '../models/user/userSettings';

import { AbsenceContract } from '../models/contracts/absences.contract';
import { ContactContract } from '../models/contracts/contacts.contract';
import { InitContract } from '../models/contracts/init.contract';
import { TypesContract } from '../models/contracts/types.contract';
import { AbsencePeriod } from '../models/project/absencePeriod';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import {
  varappconfig,
  varnotificationtriggertypes$,
  varusersettings,
} from './startup.service';
import { AppConfig } from '../models/user/appConfig';

@Injectable({
  providedIn: 'root',
})
export class IshtarPermanenceService {
  cacheBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createCacheEndpointUrl(path: string) {
    return new URL(`${this.cacheBase}/${path}`).toString();
  }

  get GetTriggerCreated() {
    return varnotificationtriggertypes$.value?.find((v) => v.name == 'Created')
      ?.id;
  }
  get GetTriggerUpdated() {
    return varnotificationtriggertypes$.value?.find((v) => v.name == 'Updated')
      ?.id;
  }
  get GetTriggerAccepted() {
    return varnotificationtriggertypes$.value?.find((v) => v.name == 'Accepted')
      ?.id;
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.cacheBase}/session/keepalive`, {});
  }

  filterQuery(filters: Filter[]) {
    if (filters.length > 0) {
      let filterString = '&filter=';
      const variabelFilterString = filters
        .map((filter: Filter) => {
          switch (filter.columnName) {
            case 'NotificationType':
              filterString =
                '&expand=NotificationType&select=*,NotificationType' +
                filterString;
              return `('${filter.value}' in ${filter.columnName}/Type)`;
            case 'Department':
              filterString =
                '&expand=Department&select=*,Department' + filterString;
              return `('${filter.value}' in ${filter.columnName}/Name)`;
            case 'Function':
              filterString =
                '&expand=Function&select=*,Function' + filterString;
              return `('${filter.value}' in ${filter.columnName}/Name)`;

            default:
              return `('${filter.value}' in ${filter.columnName})`;
          }
        })
        .join(' and ');

      return filterString + variabelFilterString;
    } else {
      return '';
    }
  }

  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.cacheBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post<UserSettings>(
        this.createCacheEndpointUrl(`startup/userSettings`),
        settings
      )
      .pipe(
        tap((x) => varusersettings.next(x)),
        map((c) => new UserSettings(c))
      );
  }

  updateAppSettings(settings: AppConfig) {
    return this.httpClient
      .post<AppConfig>(
        this.createCacheEndpointUrl(`startup/appSettings`),
        settings
      )
      .pipe(
        map((c) => new AppConfig(c)),
        tap((x) => varappconfig.next(x))
      );
  }

  getStartUpData(userEmail: string, language: string) {
    return this.httpClient.get<InitContract>(
      this.createCacheEndpointUrl(
        `startup?userEmail=${userEmail}&language=${language}`
      )
    );
  }

  getTypes() {
    return this.httpClient.get<TypesContract>(
      this.createCacheEndpointUrl(`startup/types`)
    );
  }

  getAbsencesData(startDate: DateTime, endDate: DateTime) {
    const start = startDate.toISO();
    const end = endDate.toISO();
    return this.httpClient.get<AbsenceContract>(
      this.createCacheEndpointUrl(
        `absence/contract?startDate=${start}&endDate=${end}`
      )
    );
  }

  getContactDetailsData() {
    return this.httpClient.get<ContactContract>(
      this.createCacheEndpointUrl(`contacts/contract`)
    );
  }

  getDepartments() {
    return this.httpClient
      .get<Department[]>(
        this.createCacheEndpointUrl(`department?filter=isDeleted eq false`)
      )
      .pipe(
        map((department) =>
          department.map((g) => new Department(this.camelcaseKeys(g)))
        )
      );
  }

  getContactDetails() {
    return this.httpClient
      .get<ContactDetail[]>(this.createCacheEndpointUrl(`contacts`))
      .pipe(
        map((result) =>
          result.map((c) => new ContactDetail(this.camelcaseKeys(c)))
        )
      );
  }

  addContactDetail(contactDetail: ContactDetail) {
    return this.httpClient
      .post<ContactDetail[]>(this.createCacheEndpointUrl(`contacts`), [
        contactDetail,
      ])
      .pipe(
        map((contactDetail) =>
          contactDetail.map((p) => new ContactDetail(this.camelcaseKeys(p)))
        )
      );
  }

  updateContactDetail(contactDetail: ContactDetail) {
    return this.httpClient
      .patch<ContactDetail>(
        this.createCacheEndpointUrl(`contacts`),
        contactDetail
      )
      .pipe(
        map(
          (contactDetail) =>
            new ContactDetail(this.camelcaseKeys(contactDetail))
        )
      );
  }

  removeContactDetail(ishtarPermanenceContactDetailIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`contacts`),
      {
        body: ishtarPermanenceContactDetailIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  addRecurrency(recurrency: Recurrency) {
    const addedRecurrencyIso = this.DateTimeTypeInObjectToISO(recurrency);
    return this.httpClient
      .post<Recurrency>(
        this.createCacheEndpointUrl(`recurrency`),
        addedRecurrencyIso
      )
      .pipe(
        map((recurrency) => new Recurrency(this.camelcaseKeys(recurrency)))
      );
  }

  getPermanenceTemplates(departmentId?: string) {
    return this.httpClient
      .get<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template/${departmentId}`)
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  addPermanenceTemplate(permanenceTemplate: PermanenceTemplate) {
    const newPermanenceTemplateIso =
      this.DateTimeTypeInObjectToISO(permanenceTemplate);
    return this.httpClient
      .post<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template`),
        [newPermanenceTemplateIso]
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  updatePermanenceTemplate(permanenceTemplate: PermanenceTemplate) {
    const updatedPermanenceTemplateIso =
      this.DateTimeTypeInObjectToISO(permanenceTemplate);
    return this.httpClient
      .patch<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template`),
        [updatedPermanenceTemplateIso]
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  removePermanenceTemplate(permanenceTemplateId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`startup/template`),
      {
        body: [permanenceTemplateId],
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  getPermanenceDuties(
    startOfMonth: DateTime,
    endOfMonth: DateTime,
    departments?: Department[]
  ) {
    const start = startOfMonth.toISO();
    const end = endOfMonth.toISO();
    const departmentIds = departments?.map((d) => d.id).join(',');
    const departmentString = departmentIds
      ? `&departments=${departmentIds}`
      : '';

    return this.httpClient
      .get<PermanenceDuty[]>(
        this.createCacheEndpointUrl(
          `permanence?startDate=${start}&endDate=${end}${departmentString}`
        )
      )
      .pipe(
        map((permanenceDuties) =>
          permanenceDuties.map((p) => new PermanenceDuty(this.camelcaseKeys(p)))
        )
      );
  }

  checkForOverlappingPermanenceDuties(
    startDate: DateTime,
    endDate: DateTime,
    departmentId: string,
    permanenceDutyId?: string,
    recurrencyId?: string,
    recurrencyOccurence?: number
  ) {
    const start = startDate.toISO();
    const end = endDate.toISO();
    const permanenceFilter = permanenceDutyId
      ? `&permanenceId=${permanenceDutyId}`
      : '';
    const recurrenceFilter = recurrencyId
      ? `&recurrenceId=${recurrencyId}&recurrenceOccurence=${recurrencyOccurence}`
      : '';
    return this.httpClient.get<boolean>(
      this.createCacheEndpointUrl(
        `permanence/checkOverlapping?startDate=${start}&endDate=${end}&departmentId=${departmentId}${permanenceFilter}${recurrenceFilter}`
      )
    );
  }

  checkForOverlappingAbsences(
    startDate: DateTime,
    endDate: DateTime,
    userId: string,
    absenceId?: string
  ) {
    const start = startDate.toISO();
    const end = endDate.toISO();
    return this.httpClient.get<boolean>(
      this.createCacheEndpointUrl(
        `absence/checkOverlapping?startDate=${start}&endDate=${end}&userId=${userId}${
          absenceId ? `&absenceId=${absenceId}` : ''
        }`
      )
    );
  }

  checkForOverlapDutyAbsence(startDate: DateTime, endDate: DateTime) {
    const start = startDate.toISO();
    const end = endDate.toISO();
    return this.httpClient.get<string[]>(
      this.createCacheEndpointUrl(
        `permanence/checkOverlapDutyAbsence?startDate=${start}&endDate=${end}`
      )
    );
  }

  checkForOverlapRecurrenceAbsence(recurrence: Recurrency) {
    const recurrenceToCheckIso = this.DateTimeTypeInObjectToISO(recurrence);
    return this.httpClient.post<string[]>(
      this.createCacheEndpointUrl(`absence/overlappingRecurrenceAbsence`),
      recurrenceToCheckIso
    );
  }

  getExchanges() {
    return this.httpClient
      .get<Exchange[]>(this.createCacheEndpointUrl(`exchange`))
      .pipe(
        map((exchanges) =>
          exchanges.map((e) => new Exchange(this.camelcaseKeys(e)))
        )
      );
  }

  getNotifications() {
    return this.httpClient
      .get<ApplicationNotification[]>(
        this.createCacheEndpointUrl(`notifications`)
      )
      .pipe(
        map((notifications) =>
          notifications.map(
            (n) => new ApplicationNotification(this.camelcaseKeys(n))
          )
        )
      );
  }
  getNotificationById(notificationId: string) {
    return this.httpClient
      .get<ApplicationNotification>(
        this.createCacheEndpointUrl(`notifications/${notificationId}`)
      )
      .pipe(map((n) => new ApplicationNotification(this.camelcaseKeys(n))));
  }
  createNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .post<ApplicationNotification>(
        this.createCacheEndpointUrl(`notifications`),
        this.DateTimeTypeInObjectToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(this.camelcaseKeys(n))));
  }
  updateNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .patch<ApplicationNotification>(
        this.createCacheEndpointUrl(`notifications`),
        this.DateTimeTypeInObjectToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(this.camelcaseKeys(n))));
  }
  deleteNotification(notificationId: string) {
    return this.httpClient.delete<string>(
      this.createCacheEndpointUrl(`notifications/${notificationId}`),
      {
        responseType: 'text' as 'json',
      }
    );
  }

  addExchange(
    exchange: Exchange,
    permanenceDuty: PermanenceDuty,
    isRecurrence: boolean
  ) {
    const newEchangeIso = this.DateTimeTypeInObjectToISO(exchange);
    const permanenceDutyToCheckIso =
      this.DateTimeTypeInObjectToISO(permanenceDuty);
    return this.httpClient
      .post<{
        addedExchange: Exchange;
        exchangeDuties: PermanenceDuty[];
        permanence: PermanenceDuty;
      }>(this.createCacheEndpointUrl(`exchange/${this.GetTriggerCreated}`), {
        exchange: newEchangeIso,
        permanence: permanenceDutyToCheckIso,
        isRecurrence: isRecurrence,
      })
      .pipe(
        map((response) => ({
          exchangeDuties: response.exchangeDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          addedExchange: new Exchange({
            ...this.camelcaseKeys(response.addedExchange),
          }),
          permanence: new PermanenceDuty(
            this.camelcaseKeys(response.permanence)
          ),
        }))
      );
  }

  removeExchange(exchange: Exchange) {
    const exchangeToDeleteIso = this.DateTimeTypeInObjectToISO(exchange);
    return this.httpClient
      .delete<{
        deletedExchangeId: string;
        originalPermanence: PermanenceDuty;
      }>(this.createCacheEndpointUrl(`exchange`), {
        body: exchangeToDeleteIso,
      })
      .pipe(
        map((response) => ({
          deletedExchangeId: response.deletedExchangeId,
          originalPermanence: new PermanenceDuty(
            this.camelcaseKeys(response.originalPermanence)
          ),
        }))
      );
  }

  exchangeAccepted(
    exchangeToUpdateId: string,
    originalDuty: PermanenceDuty,
    exchangeDuty: PermanenceDuty,
    splittedDuties: PermanenceDuty[]
  ) {
    const orignalToIso = this.DateTimeTypeInObjectToISO(originalDuty);
    const dutyExchangeToIso = this.DateTimeTypeInObjectToISO(exchangeDuty);
    const splittedToIso = splittedDuties.map((d) =>
      this.DateTimeTypeInObjectToISO(d)
    );

    return this.httpClient
      .post<{
        createdDuties: PermanenceDuty[];
        createdExchangeDuty: PermanenceDuty;
        updatedOriginalDuty: PermanenceDuty;
        deletedOriginalDuty: string;
        updatedExchange: Exchange;
        oldExchangeId: string;
      }>(
        this.createCacheEndpointUrl(
          `exchange/accepted/${this.GetTriggerAccepted}`
        ),
        {
          exchangeToUpdateId: exchangeToUpdateId,
          originalDuty: orignalToIso,
          exchangeDuty: dutyExchangeToIso,
          splittedDuties: splittedToIso,
        }
      )
      .pipe(
        map((response) => ({
          createdDuties: response.createdDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          createdExchangeDuty: new PermanenceDuty(
            this.camelcaseKeys(response.createdExchangeDuty)
          ),
          updatedOriginalDuty: new PermanenceDuty(
            this.camelcaseKeys(response.updatedOriginalDuty)
          ),
          deletedOriginalDuty: response.deletedOriginalDuty,
          updatedExchange: new Exchange({
            ...this.camelcaseKeys(response.updatedExchange),
            permanenceDuty: new PermanenceDuty(
              this.camelcaseKeys(response.updatedOriginalDuty)
            ),
          }),
          oldExChangeId: response.oldExchangeId,
        }))
      );
  }

  getPermanenceDutiesForUser(
    startDate: DateTime,
    endDate: DateTime,
    userId: string
  ) {
    const parsedStartDate = startDate.toISO();
    const parsedEndDate = endDate.toISO();
    return this.httpClient
      .get<{ permanenceDuties: PermanenceDuty[]; lastDuty: boolean }>(
        this.createCacheEndpointUrl(
          `permanence/dutiesForUser?startDate=${parsedStartDate}&endDate=${parsedEndDate}&userId=${userId}`
        )
      )
      .pipe(
        map((response) => ({
          permanenceDuties: response.permanenceDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          lastDuty: response.lastDuty,
        }))
      );
  }

  addPermanenceDuty(permanenceDuty: PermanenceDuty, permanenceUsers: string[]) {
    const newPermanenceDutyIso = this.DateTimeTypeInObjectToISO(permanenceDuty);
    return this.httpClient
      .post<PermanenceDuty[]>(
        this.createCacheEndpointUrl(
          `permanence/addPermanence?triggerType=${this.GetTriggerCreated}`
        ),
        { permanence: newPermanenceDutyIso, permanenceUserIds: permanenceUsers }
      )
      .pipe(
        map((permanenceDuties) =>
          permanenceDuties.map((p) => new PermanenceDuty(this.camelcaseKeys(p)))
        )
      );
  }

  updatePermanenceDuty(
    permanenceDuty: PermanenceDuty,
    permanenceUsers: string[]
  ) {
    const updatedPermanenceDutyIso =
      this.DateTimeTypeInObjectToISO(permanenceDuty);
    return this.httpClient
      .patch<PermanenceDuty>(
        this.createCacheEndpointUrl(
          `permanence/updatePermanence?triggerType=${this.GetTriggerUpdated}`
        ),
        {
          permanence: updatedPermanenceDutyIso,
          permanenceUserIds: permanenceUsers,
        }
      )
      .pipe(
        map(
          (permanenceDuty) =>
            new PermanenceDuty(this.camelcaseKeys(permanenceDuty))
        )
      );
  }

  updateDutyComment(permanence: PermanenceDuty) {
    const permanenceDutyIso = this.DateTimeTypeInObjectToISO(permanence);
    return this.httpClient
      .patch<PermanenceDuty[]>(
        this.createCacheEndpointUrl(`permanence/updateCommentDuty`),
        permanenceDutyIso
      )
      .pipe(
        map(
          ([permanenceDuty]) =>
            new PermanenceDuty(this.camelcaseKeys(permanenceDuty))
        )
      );
  }

  removePermanenceDuty(ishtarPermanenceId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(
        `permanence/deletePermanence/${ishtarPermanenceId}`
      )
    );
  }

  getRecurrencies() {
    return this.httpClient
      .get<Recurrency[]>(this.createCacheEndpointUrl(`recurrency`))
      .pipe(
        map((recurrencies) =>
          recurrencies.map((r) => new Recurrency(this.camelcaseKeys(r)))
        )
      );
  }

  updateRecurrence(
    recurrency: Recurrency,
    startDate: DateTime,
    endDate: DateTime
  ) {
    const parsedStartDate = startDate.toISO();
    const parsedEndDate = endDate.toISO();
    const updatedRecurrenceIso = this.DateTimeTypeInObjectToISO(recurrency);
    return this.httpClient
      .patch<{
        updatedRecurrency: Recurrency;
        recurrencyDuties: PermanenceDuty[];
      }>(
        this.createCacheEndpointUrl(
          `recurrency?startDate=${parsedStartDate}&endDate=${parsedEndDate}`
        ),
        updatedRecurrenceIso
      )
      .pipe(
        map((response) => {
          return {
            updatedRecurrency: new Recurrency(
              this.camelcaseKeys(response.updatedRecurrency)
            ),
            recurrencyDuties: response.recurrencyDuties.map(
              (p) => new PermanenceDuty(this.camelcaseKeys(p))
            ),
          };
        })
      );
  }

  removeRecurrence(ishtarPermanenceRecurrencyId: string) {
    return this.httpClient
      .delete<{
        deletedPermanenceIds: string[];
        deletedRecurrencyId: string;
      }>(
        this.createCacheEndpointUrl(
          `recurrency/${ishtarPermanenceRecurrencyId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: this.msal.userId,
          },
        }
      )
      .pipe(
        map((response) => ({
          deletedPermanenceIds: response.deletedPermanenceIds,
          deletedRecurrencyId: response.deletedRecurrencyId,
        }))
      );
  }

  getAbsences(startDate: DateTime, endDate: DateTime) {
    const parsedStartDate = startDate.toISO();
    const parsedEndDate = endDate.toISO();
    return this.httpClient
      .get<Absence[]>(
        this.createCacheEndpointUrl(
          `absence/getAbsences?startDate=${parsedStartDate}&endDate=${parsedEndDate}`
        )
      )
      .pipe(
        map((absences) =>
          absences.map((a) => new Absence(this.camelcaseKeys(a)))
        )
      );
  }

  getAbsencesForUser() {
    return this.httpClient
      .get<Absence[]>(this.createCacheEndpointUrl(`absence/absencesForUser`))
      .pipe(
        map((absences) =>
          absences.map((a) => new Absence(this.camelcaseKeys(a)))
        )
      );
  }

  addAbsences(absence: Absence) {
    const newAbsenceISO = this.DateTimeTypeInObjectToISO(absence);
    return this.httpClient
      .post<Absence>(
        this.createCacheEndpointUrl(
          `absence?triggerType=${this.GetTriggerCreated}`
        ),
        newAbsenceISO
      )
      .pipe(
        map((addedAbsence) => new Absence(this.camelcaseKeys(addedAbsence)))
      );
  }

  updateAbsences(absence: Absence) {
    const updatedAbsenceISO = this.DateTimeTypeInObjectToISO(absence);
    return this.httpClient
      .patch<Absence>(this.createCacheEndpointUrl(`absence`), updatedAbsenceISO)
      .pipe(map((absence) => new Absence(this.camelcaseKeys(absence))));
  }

  removeAbsences(ishtarPermanenceAbsenceId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absence/${ishtarPermanenceAbsenceId}`)
    );
  }

  getContactDetailParams() {
    return this.httpClient
      .get<ContactDetailParam[]>(this.createCacheEndpointUrl(`contactparams`))
      .pipe(
        map((contactDetailParams) =>
          contactDetailParams.map(
            (c) => new ContactDetailParam(this.camelcaseKeys(c))
          )
        )
      );
  }

  addContactDetailParam(contactDetailParam: ContactDetailParam) {
    return this.httpClient
      .post<ContactDetailParam[]>(
        this.createCacheEndpointUrl(`contactparams`),
        [contactDetailParam]
      )
      .pipe(
        map(
          (contactDetailParam) =>
            new ContactDetailParam(this.camelcaseKeys(contactDetailParam[0]))
        )
      );
  }

  updateContactDetailParam(contactDetailParam: ContactDetailParam) {
    return this.httpClient
      .patch<ContactDetailParam[]>(
        this.createCacheEndpointUrl(`contactparams`),
        [contactDetailParam]
      )
      .pipe(
        map(
          (contactDetailParam) =>
            new ContactDetailParam(this.camelcaseKeys(contactDetailParam[0]))
        )
      );
  }

  getContactDetailParamValues() {
    return this.httpClient
      .get<ContactDetailParamValue[]>(
        this.createCacheEndpointUrl(`contactparamvalues`)
      )
      .pipe(
        map((contactDetailParamValues) =>
          contactDetailParamValues.map(
            (c) => new ContactDetailParamValue(this.camelcaseKeys(c))
          )
        )
      );
  }

  updateContactDetailParamValue(
    contactDetailParamValue: ContactDetailParamValue
  ) {
    return this.httpClient
      .patch<ContactDetail[]>(
        this.createCacheEndpointUrl(`contactparamvalues`),
        [contactDetailParamValue]
      )
      .pipe(
        map(
          (contactDetailParamValue) =>
            new ContactDetailParamValue(
              this.camelcaseKeys(contactDetailParamValue[0])
            )
        )
      );
  }

  addContactDetailParamValue(contactDetailParamValue: ContactDetailParamValue) {
    return this.httpClient
      .post<ContactDetailParamValue[]>(
        this.createCacheEndpointUrl(`contactparamvalues`),
        [contactDetailParamValue]
      )
      .pipe(
        map(
          (contactDetailParamValue) =>
            new ContactDetailParamValue(
              this.camelcaseKeys(contactDetailParamValue[0])
            )
        )
      );
  }

  removeContactDetailParamValues(paramValueIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`contactparamvalues`),
      {
        body: paramValueIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  removeContactDetailParam(paramId: string) {
    return this.httpClient.delete<string>(
      this.createCacheEndpointUrl(`contactparams/${paramId}`),
      {
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
        responseType: 'text' as 'json',
      }
    );
  }

  updateDepartment(departments: Department[]) {
    return this.httpClient
      .patch<Department[]>(
        this.createCacheEndpointUrl(`department`),
        departments
      )
      .pipe(
        map((departments) =>
          departments.map((p) => new Department(this.camelcaseKeys(p)))
        )
      );
  }

  addDepartment(departments: Department[]) {
    return this.httpClient
      .post<Department[]>(
        this.createCacheEndpointUrl(`department`),
        departments
      )
      .pipe(
        map((departments) =>
          departments.map((s) => new Department(this.camelcaseKeys(s)))
        )
      );
  }

  removeDepartment(ishtarPermanenceDepartmentIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`department`),
      {
        body: ishtarPermanenceDepartmentIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  getAbsenceTypes() {
    return this.httpClient
      .get<AbsenceType[]>(this.createCacheEndpointUrl(`absenceType`))
      .pipe(
        map((absenceType) =>
          absenceType.map((g) => new AbsenceType(this.camelcaseKeys(g)))
        )
      );
  }

  updateAbsenceType(absenceTypes: AbsenceType[]) {
    return this.httpClient
      .patch<AbsenceType[]>(
        this.createCacheEndpointUrl(`absenceType`),
        absenceTypes
      )
      .pipe(
        map((absenceTypes) =>
          absenceTypes.map((p) => new AbsenceType(this.camelcaseKeys(p)))
        )
      );
  }

  addAbsenceType(absenceTypes: AbsenceType[]) {
    return this.httpClient
      .post<AbsenceType[]>(
        this.createCacheEndpointUrl(`absenceType`),
        absenceTypes
      )
      .pipe(
        map((absenceTypes) =>
          absenceTypes.map((s) => new AbsenceType(this.camelcaseKeys(s)))
        )
      );
  }

  removeAbsenceType(ishtarPermanenceAbsenceTypeIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absenceType`),
      {
        body: ishtarPermanenceAbsenceTypeIds,
      }
    );
  }

  getAbsencePeriods() {
    return this.httpClient
      .get<AbsencePeriod[]>(this.createCacheEndpointUrl(`absencePeriod`))
      .pipe(
        map((p) =>
          p.map((a: AbsencePeriod) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  updateAbsencePeriod(absencePeriods: AbsencePeriod[]) {
    const updatedAbsencePeriodsIso = absencePeriods.map((s) =>
      this.DateTimeTypeInObjectToISO(s)
    );
    return this.httpClient
      .patch<AbsencePeriod[]>(
        this.createCacheEndpointUrl(`absencePeriod`),
        updatedAbsencePeriodsIso
      )
      .pipe(
        map((absencePeriods) =>
          absencePeriods.map((a) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  addAbsencePeriod(absencePeriods: AbsencePeriod[]) {
    const newAbsencePeriodsIso = absencePeriods.map((s) =>
      this.DateTimeTypeInObjectToISO(s)
    );
    return this.httpClient
      .post<AbsencePeriod[]>(
        this.createCacheEndpointUrl(`absencePeriod`),
        newAbsencePeriodsIso
      )
      .pipe(
        map((absencePeriods) =>
          absencePeriods.map((a) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  removeAbsencePeriod(ishtarPermanenceAbsencePeriodIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absencePeriod`),
      {
        body: ishtarPermanenceAbsencePeriodIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
  DateTimeTypeInObjectToISO(obj: any): any {
    if (Array.isArray(obj))
      return [...obj.map((o) => this.DateTimeTypeInObjectToISO(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
          [e[0]]:
            e[1] && e[1] instanceof DateTime
              ? e[1].toISO()
              : this.DateTimeTypeInObjectToISO(e[1]),
        }),
        {}
      );
    else return obj;
  }
}
