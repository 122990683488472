import { Action, createReducer, on } from '@ngrx/store';
import {
  createNotificationResolved,
  deleteNotificationResolved,
  getNotificationsResolved,
  resetSlice,
  updateNotificationResolved,
} from './applicationNotification.action';
import { ApplicationNotification } from 'processdelight-angular-components';

export const featureSlice = 'notification';

export interface State {
  notifications: ApplicationNotification[];
}

const defaultState: State = {
  notifications: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return notificationReducer(state, action);
}

export const initialState: State = defaultState;
export const notificationReducer = createReducer(
  initialState,
  on(getNotificationsResolved, (state, { notifications }) => ({
    ...state,
    notifications,
  })),
  on(createNotificationResolved, (state, { notification, instantMessage }) => ({
    ...state,
    notifications: instantMessage
      ? state.notifications
      : [...state.notifications, notification],
  })),
  on(updateNotificationResolved, (state, { notification, instantMessage }) => ({
    ...state,
    notifications: instantMessage
      ? state.notifications
      : state.notifications.map((n) =>
          n.id === notification.id ? notification : n
        ),
  })),
  on(deleteNotificationResolved, (state, { notificationId }) => ({
    ...state,
    notifications: state.notifications.filter((n) => n.id !== notificationId),
  })),

  on(resetSlice, () => initialState)
);
